import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
  title?: string
}

export const RoomsList = memo(function RoomsList({ rooms, title }: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInOutAnimation>
          <Title>{title}</Title>
        </FadeInOutAnimation>
      ) : null}
      <List>
        {rooms.map((item, index) => (
          <FadeInOutAnimation key={index}>
            <Room isOdd={index % 2 === 0} {...item} />
          </FadeInOutAnimation>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem 0;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    margin: 5.25rem 0;
  }
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const List = styled.div`
  margin-top: 3.75rem;
  > div {
    margin-top: 1.25rem;
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    > div {
      margin-top: 1.5rem;
    }
  }
`

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import * as Icon from 'react-feather'
import LazyLoad from 'react-lazyload'

export interface Props {
  isOdd?: boolean
  image?: string
  languageCode: string
  pax?: string
  size?: string
  title: string
  URL?: string
}

export const Room = memo(function Room({
  isOdd,
  image,
  languageCode,
  pax,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container row space="between" wrap>
      <LeftSide style={{ order: isOdd ? 1 : 2 }}>
        {image ? (
          <LazyLoad once>
            <BackgroundImage
              className="room-bg"
              style={{ backgroundImage: `url(${image})` }}
            />
          </LazyLoad>
        ) : null}
        <TextWrap dial={5}>
          <Title>{title}</Title>
          <InfoWrap dial={2} row>
            {size ? (
              <Size dial={5} row>
                <Icon.Box />
                {size}
              </Size>
            ) : null}
            {pax ? (
              <Pax dial={5} row>
                <Icon.User />
                {pax}
              </Pax>
            ) : null}
          </InfoWrap>
          {URL ? (
            <CTA label={useVocabularyData('details', languageCode)} URL={URL} />
          ) : null}
        </TextWrap>
      </LeftSide>
      <RightSide style={{ order: isOdd ? 2 : 1 }}>
        {URL ? (
          <Link to={URL}>
            {image ? (
              <LazyLoad once>
                <BackgroundImage style={{ backgroundImage: `url(${image})` }} />
              </LazyLoad>
            ) : null}
          </Link>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: calc(50% - 1px);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 34%;
  position: relative;
  .room-bg {
    opacity: 0.2;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2 !important;
    padding-bottom: 0;
    .room-bg {
      display: none;
    }
  }
`

const BackgroundImage = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const RightSide = styled.div`
  width: calc(50% - 1px);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 34%;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    order: 1 !important;
    padding-bottom: 66%;
  }
`

const TextWrap = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3.75rem 1.5rem 6rem;
  position: absolute;
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 3rem 1.5rem 3.75rem;
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 2.25rem 1.5rem 3rem;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
  position: relative;
`

const InfoWrap = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 0.75rem;
  margin-top: 1.3125rem;
  text-transform: uppercase;
  svg {
    height: 1rem;
    opacity: 0.35;
    margin-right: 0.5625rem;
  }
`

const Size = styled(FlexBox)`
  margin-right: 1.25rem;
`

const Pax = styled(FlexBox)``

const CTA = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-out;

  @media (max-width: 767px) {
    margin-top: 2.25rem;
    position: relative;
    left: auto;
    transform: none;
  }
`

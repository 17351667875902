import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import {
  Props as ServicesProps,
  Service,
} from 'app/components/IconsServices/Service'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import * as Icon from 'react-feather'
import LazyLoad from 'react-lazyload'

export interface Props {
  checklist?: string[]
  image?: string
  services?: ServicesProps[]
  servicesTitle?: string
  title?: string
}

export const QuickInfo = memo(function QuickInfo({
  checklist,
  image,
  services,
  servicesTitle,
  title,
}: Props) {
  return (
    <Container row space="between" tag="section">
      <FadeInOutAnimation>
        <>
          {image ? (
            <LazyLoad once>
              <Image style={{ backgroundImage: `url(${image})` }} />
            </LazyLoad>
          ) : null}
          <TextWrap>
            {title ? <Title>{title}</Title> : null}
            {checklist ? (
              <Checklist>
                {checklist.map((item, index) => (
                  <ChecklistItem key={index}>
                    <Icon.Check />
                    {item}
                  </ChecklistItem>
                ))}
              </Checklist>
            ) : null}
          </TextWrap>
        </>
      </FadeInOutAnimation>
      <FadeInOutAnimation>
        <Services>
          {servicesTitle ? (
            <ServicesTitle>{servicesTitle}</ServicesTitle>
          ) : null}
          {services ? (
            <ServicesList row wrap>
              {services.map((item, index) => {
                return <Service key={index} {...item} />
              })}
            </ServicesList>
          ) : null}
        </Services>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 76.5rem;
  margin: 9.75rem auto;
  padding: 0 1.5rem;
  > div {
    width: calc(40% - 1px);
    position: relative;
    &:last-of-type {
      width: calc(60% - 1px);
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      margin-top: 3.75rem;
    }
  }

  @media (max-width: 1199px) {
    margin: 7.5rem auto;
  }

  @media (max-width: 767px) {
    display: block;
    margin: 5.25rem auto;
    padding: 0;
    > div {
      width: 100%;
      &:first-of-type {
        padding: 1.5rem;
      }
      &:last-of-type {
        width: 100%;
        margin-top: 0;
      }
    }
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const TextWrap = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 3.75rem;
  padding: 3rem 3.75rem 7.3125rem 3rem;
  position: relative;

  @media (max-width: 1199px) {
    margin: 3rem;
    padding: 2.25rem 3rem 5.25rem 2.25rem;
  }

  @media (max-width: 1023px) {
    margin: 1.5rem;
    padding: 2.25rem 1.5rem 3.75rem;
  }

  @media (max-width: 767px) {
    margin: 0;
    padding: 2.25rem 1.5rem 3rem;
  }
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2.25rem;
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
    margin-left: 1.5rem;
  }
`

const Checklist = styled.ul``

const ChecklistItem = styled.li`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  padding-left: 1.25rem;
  position: relative;
  svg {
    height: 18px;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    position: absolute;
    top: 0.1875rem;
    left: -0.375rem;
  }

  @media (max-width: 767px) {
    padding-left: 2rem;
    svg {
      top: 0.0625rem;
    }
  }
`

const Services = styled.div`
  padding: 3.75rem 3.75rem 7.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 3rem 3rem 5.25rem;
  }

  @media (max-width: 767px) {
    padding: 2.25rem 2.25rem 3rem;
  }
`

const ServicesTitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
`

const ServicesList = styled(FlexBox)`
  max-width: 31.25rem;
  margin: auto;
  > div {
    width: 33%;
    margin-top: 3.75rem;
    div {
      width: 3rem;
      height: 3rem;
      img {
        height: 1.25rem;
      }
    }
    h3 {
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 1023px) {
    > div {
      margin-top: 3rem;
    }
  }

  @media (max-width: 767px) {
    > div {
      margin-top: 2.25rem;
    }
  }
`
